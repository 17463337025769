import './index.css';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

function App() {
  return (
    <div className="app">
      <div className="header">
        <div className='headerText'>k</div>
        <div className='headerTextO'>O</div>
        <div className='headerText'>selige rom</div>
      </div>
      <div className='info'>
        Denne nettsiden er under utvikling og vil oppdateres fortløpende
      </div>
      <div className='contactSection'>
        {/* <div className='contact'>
            Kontaktinformasjon
        </div> */}
        <div className='phone'>
            <PhoneIcon style={{ fontSize: 20, marginTop: 3, marginRight: 8 }}/>
            +47 901 96 564
        </div>
        <div className='email'>
            <EmailIcon style={{ fontSize: 20, marginTop: 5, marginRight: 8 }}/>
            koseligerom@gmail.com
        </div>
      </div>
    </div>
  );
}

export default App;
